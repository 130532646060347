import React, { useEffect } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Services from "./components/pages/Services";
import Products from "./components/pages/Products";
import SignUp from "./components/pages/SignUp";
import ReactGA from "react-ga";
import Resume from "./components/pages/Resume";

function App() {
  // not really working now
  useEffect(() => {
    ReactGA.initialize("G-RYMVK9830Y");
    return () => {
      // to report page view
      ReactGA.pageview(window.location.pathname + window.location.search);
    };
  }, []);
  return (
    //fragment
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
          <Route
            path={`${process.env.PUBLIC_URL}/services`}
            exact
            component={Services}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/projects`}
            exact
            component={Products}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/sign-up`}
            exact
            component={SignUp}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/resume`}
            exact
            component={Resume}
          />
        </Switch>
      </Router>
    </>
  );
}

// RTL support
document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");

// document.getElementsByName("hii").setAttribute("dir", "rtl");

export default App;

import "../../App.css";
import HercoSection from "../HeroSection";

import React from "react";
import Cards from "../Cards";
import Footer from "../Footer";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Or Saada</title>
        <meta name="description" content="Software Engineer" />
        <meta name="theme-color" content="#ccc" />
        <link
          rel="icon"
          type="image/png"
          href="/images/saados-logo.jpeg"
          sizes="16x16"
        />
      </Helmet>

      <HercoSection />
      {/* <Cards /> */}
      <Footer />
    </>
  );
}

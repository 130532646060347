import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  // when shrink the buttons disapear
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  // fix after refresh the signup button will not show
  useEffect(() => {
    showButton();
  }, []);

  // everytime screen is resized call showbutton
  window.addEventListener("resize", showButton);
  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                to={`${process.env.PUBLIC_URL}/`}
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`${process.env.PUBLIC_URL}/services`}
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Art Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`${process.env.PUBLIC_URL}/projects`}
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Software Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`${process.env.PUBLIC_URL}/resume`}
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Resume
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                to="/sign-up"
                className="nav-links-mobile"
                onClick={closeMobileMenu}
              >
                Sign Up
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                to={`${process.env.PUBLIC_URL}/sign-up`}
                className="nav-links-mobile"
                onClick={closeMobileMenu}
              >
                Contact
              </Link>
            </li>
          </ul>

          {/* disapear the nav menu after click on page */}
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          {/* <div style={{ marginLeft: "100px" }}>
            {button && <Button buttonStyle="btn--outline">SIGN UP</Button>}
          </div> */}
          <Link
            to={`${process.env.PUBLIC_URL}/`}
            className="navbar-logo"
            onClick={closeMobileMenu}
          >
            <img
              src="/images/saados-logo.jpeg"
              className="saados-logo"
              // class="custom-style"
              alt="SaaDOS"
            />
            {/* SaaDOS <i className="fab fa-typo3" /> */}
          </Link>
        </div>
      </nav>
    </>
  );
}

// document.ge .getElementsByTagName('html')[0].setAttribute("dir", "rtl");

export default Navbar;

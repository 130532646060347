import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";

export default function HeroSection() {
  return (
    <div className="hero-container">
      {/* <video src="/videos/video-1.mp4" autoPlay loop muted></video> */}
      <h2>Hi, check my resume and projects on the links</h2>
      {/* <p>what are you waiting for?!</p> */}
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          to="/resume"
        >
          Look At My Resume
        </Button>
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          to="/projects"
        >
          My Projects
        </Button>
        {/* <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          onClick={() => {
            window.open(
              "https://stackoverflow.com/questions/45046030/maintaining-href-open-in-new-tab-with-an-onclick-handler-in-react",
              "_blank"
            );
          }}
        >
          Look At My Resume <i className="far fa-play-circle" />
        </Button> */}
      </div>
    </div>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
// import * as serviceWorker from './serviceWorker';

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
// const rootElement =

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

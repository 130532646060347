import React from "react";
import "../../App.css";
import FormSignUp from "../FormSignUp";
import FormVer2 from "../FormVer2";

export default function SignUp() {
  return (
    <>
      {/* <h1 className="sign-up">SIGN UP</h1> */}
      {/* <FormSignUp />
      <FormVer2 /> */}
    </>
  );
}

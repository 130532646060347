import React from "react";
import "../../App.css";
import ArtProjects from "../ArtProjects";
import { DropDownCars } from "../Dropdown";
import Footer from "../Footer";
// import Footer from "../Footer";

export default function Services() {
  // const handleClick = () =>{
  //   console.log('Click happened');
  // }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ArtProjects />
      </div>
      <Footer />
    </>
  );
}

import React from "react";
import CardItem from "./CardItem";
// import "./Cards.css";
import { Link } from "react-router-dom";
// import Image from 'react-bootstrap/Image'
import Dropdown from "react-bootstrap/DropdownButton";
import DropdownButton from "react-bootstrap/DropdownButton";
import ImageGallery from "react-image-gallery";
import "./ArtProjects.css";
import drawing1 from "../public/images/art/1.jpeg";
import drawing2 from "../public/images/art/2.jpeg";
import drawing3 from "../public/images/art/3.jpeg";

const images = [
  {
    original: drawing1,
    thumbnail: drawing1,
  },
  {
    original: drawing2,
    thumbnail: drawing2,
  },
  {
    original: drawing3,
    thumbnail: drawing3,
  },
];

export default function ArtProjects() {
  return (
    <>
      <ImageGallery items={images} />
    </>
  );
}

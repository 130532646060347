import React, { useState } from "react";
import { Button } from "./Button";
import "./Footer.css";
import { Link } from "react-router-dom";

// const nodemailer = require("nodemailer");

function Footer() {
  // constructor(props){
  //   super(props);
  //   this.state = {value: ''}

  //   this.handleChange = this.handleChange.bind(this);
  //   this.handleSubmit = this.handleSubmit.bind(this);
  // }

  const [value1, setValue] = useState("");

  // let handleChange = (event) => {
  //   setValue({ value1: event.target.value });
  // };

  let handleSubmit = (event) => {
    alert("A name was submitted: " + value1);
    event.preventDefault();
  };

  return (
    <div className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading">
          ?Want to get update about my new projects
        </p>
        <p className="footer-subscription-text">You can unsubscribe any time</p>
        <div className="input-areas">
          <form>
            <input
              type="email"
              name="email"
              placeholder="email"
              className="footer-input"
              // value={value}
              onChange={(e) => setValue(e.target.value)}
              // onChange={handleChange}
            />
            <Button
              buttonStyle="btn--outline"
              buttonSize="btn--large"
              onClick={handleSubmit}
            >
              join us
            </Button>
          </form>
        </div>
      </section>
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            {/* <h2>Contact Us</h2>
            <Link to="/sign-up">How it works</Link>
            <Link to="/">Testimonials</Link>
            <Link to="/">Careers</Link>
            <Link to="/">Inverstors</Link>
            <Link to="/">Terms of Service</Link> */}
          </div>
        </div>
        {/* <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Contact Us</h2>
            <Link to="/sign-up">How it works</Link>
            <Link to="/">Testimonials</Link>
            <Link to="/">Careers</Link>
            <Link to="/">Inverstors</Link>
            <Link to="/">Terms of Service</Link>
          </div>
        </div> */}
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            {/* <Link to="/" className="social-logo">
              {/* <img src={require("../public/images/background1.jpeg")} /> */}
            {/* <img src="src/images/background1.jpeg"></img> */}
            {/* SaaDOS <i className="fab fa-typo3"></i> */}
            {/*</Link> */}
            <small className="website-rights">
              Copyright © 2021 SaaDOS.
              <br /> All Rights Reserved
            </small>
            <div className="social-icons">
              <a
                className="social-icon-link facebook"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/saadaor/"
              >
                <i className="fab fa-facebook-f" />
              </a>
              {/* <a
                className="social-icon-link instagram"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/roniversal_ashkelon/"
              >
                <i className="fab fa-instagram" />
              </a> */}
              <a
                className="social-icon-link linkedin"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/or-saada/"
              >
                <i className="fab fa-linkedin" />
              </a>

              {/* <Link
                className="social-icon-link linkedin"
                to="/"
                target="_blank"
                aria-label="Linkdin"
              >
                <i className="fab fa-linkedin"></i>
              </Link> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;

import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import samplePDF from "./orsaada_cv.pdf";
import "../Resume.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Resume(props) {
  const pdfWrapperRef = React.useRef();

  return (
    <>
      <div className="center" ref={pdfWrapperRef}>
        <div id="ResumeContainer">
          <Document className={"PDFDocument"} file={samplePDF}>
            <Page
              className={"PDFPage PDFPageOne"}
              pageNumber={1}
              renderTextLayer={false}
              renderInteractiveForms={false}
            />
          </Document>
        </div>
      </div>
    </>
  );
}

export default Resume;

import React from "react";
import "./Dropdown.css";

export class DropDownCars extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "coconut" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    alert("Your favorite flavor is: " + this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          Pick your favorite flavor:
          <select value={this.state.value} onChange={this.handleChange}>
            <option value="grapefruit">Grapefruit</option>
            <option value="lime">Lime</option>
            <option value="coconut">Coconut</option>
            <option value="mango">Mango</option>
          </select>
        </label>
        <input type="submit" value="Submit" />
      </form>
    );
  }
}

// // export const DropDownCars = (props) => (
// //     <select className="dropdown-car" name={props.name} value={props.value} onChange={props.handleChange}>
// //       <option class="nav-links" value="A">טויוטה</option>
// //       <option class="nav-links" value="B">לקסוס</option>
// //       <option class="nav-links" value="C">סובארו</option>
// //       <option class="nav-links" value="B">מיצובישי</option>
// //       <option class="nav-links" value="C">במוו</option>
// //       <option class="nav-links" value="C">אאודי</option>
// //     </select>
// //   )

// export class DropDownCars extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { fruit: 0 };
//   }
//   handleChange(event) {
//     console.log(event.target.value);
//     this.setState({ [event.target.name]: event.target.value });
//     // this.setState({title: event.target.value})
//   }
//   render() {
//     return (
//       <select
//         className="dropdown-car"
//         value={this.state.fruit}
//         onChange={(e) => this.handleChange(e)}
//       >
//         <option class="nav-links" value="A">
//           טויוטה
//         </option>
//         <option class="nav-links" value="B">
//           לקסוס
//         </option>
//         <option class="nav-links" value="C">
//           סובארו
//         </option>
//         <option class="nav-links" value="B">
//           מיצובישי
//         </option>
//         <option class="nav-links" value="C">
//           במוו
//         </option>
//         <option class="nav-links" value="C">
//           אאודי
//         </option>
//       </select>
//     );
//   }
// }

// // export function DropDownCars(props) {
// //   function handleChange (event) {
// //     console.log(event.target.value);
// //     this.setState({title: event.target.value})
// //   }
// //   // function handleChange(e) {
// //   //   console.log(e.target.value);
// //   // }
// //   return (
// //   <select className="dropdown-car" name={props.name} value={props.value} onChange={handleChange}>
// //     <option class="nav-links" value="A">טויוטה</option>
// //     <option class="nav-links" value="B">לקסוס</option>
// //     <option class="nav-links" value="C">סובארו</option>
// //     <option class="nav-links" value="B">מיצובישי</option>
// //     <option class="nav-links" value="C">במוו</option>
// //     <option class="nav-links" value="C">אאודי</option>
// //   </select>
// // )
// // }

import React from "react";
import "../../App.css";
import ArtProjects from "../ArtProjects";
import Projects from "../Projects";

export default function Products() {
  // return <h1 className="products">PRODUCTS</h1>;
  return (
    <>
      <Projects />
    </>
  );
}

import React from "react";
import CardItem from "./CardItem";
import "./Cards.css";
import { Link } from "react-router-dom";
// import Image from 'react-bootstrap/Image'
import Dropdown from "react-bootstrap/DropdownButton";
import DropdownButton from "react-bootstrap/DropdownButton";

export default function Projects() {
  return (
    <div className="cards">
      <h1>Software Projects</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="/images/ai-logo.jpeg"
              text="Search Algorithms in Artificial Intelligence, based on A* algorithm"
              label="Artificial Intelligence"
              path="https://github.com/orsaada/Search-in-Artificial-Intelligence"
            />
            <CardItem
              src="/images/movies-logo.jpeg"
              text="IMDB - data exploration, analytics and movie rating prediction"
              label="Data Science"
              path="https://github.com/orsaada/ecommerce-course-data-science"
            />
            <CardItem
              src="/images/client-server-logo.png"
              text="Client- Server communimcation - support the upload,
              download and lookup of files"
              label="System Programming - Client-Server"
              path="https://github.com/orsaada/system-programming-assignment-3"
            />
            <CardItem
              src="/images/ecommerce-logo.png"
              text="Ecommerce - 3 layer architecture, GUI, scale testing"
              label="Ecommerce Website - Flask Python"
              path="https://github.com/orsaada/ecommerce-site"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="/images/face-recognition.jpeg"
              text="Microsoft Video Indexer - API connection, GUI, Data analysis and improvement algorithm for facial recognition"
              label="Final Project - Video Analysis"
              path="https://github.com/orsaada/VisualizePrep"
            />
            <CardItem
              src="/images/rest-api-logo.png"
              text="Restful Spring Boot API"
              label="Spring Boot API"
              path="https://github.com/orsaada/springboot1"
            />
            <CardItem
              src="/images/logo-roniversal.jpeg"
              text="React Native webView of wordpress website - emporary"
              label="React Native"
              path="https://play.google.com/store/apps/details?id=com.roniversal.app"
            />
            <CardItem
              src="/images/github_PNG40.png"
              text="More projects and programming environments experience"
              label="More Projects"
              path="https://github.com/orsaada"
            />
          </ul>
          <h2>
            Currently working on React Native App Scheduler for a family
            business <br />
            You can talk with me for more details
            <a href="mailto:orssaada@gmail.com">
              <br />
              orssaada@gmail.com
            </a>
          </h2>
        </div>
      </div>
    </div>
  );
}
